:root {
    /* colors */
    --color-1: #ffffff;
    --color-2: #008ae3;
    --color-3: #ffffff;
    --color-4: #000000;
    --color-5: #000000;
    --color-6: #1a1a1a;
    --color-7: #8a8a8a;
    --color-8: #8e8e8e;
    --color-9: #e8e8e8;
    --color-10: #fafafa;
    --color-11: #e3e4e4;
    --color-12: #fffefe;
    --color-13: #dfdfdf;
    --color-14: transparent;
    /* fonts */
    --font-1: "Inter Tight";
    --font-2: "Inter";
    --font-3: "Inter";
    /* font sizes */
    --font-size-1: 0.694rem;
    --font-size-2: 0.833rem;
    --font-size-3: 1rem;
    --font-size-4: 1.2rem;
    --font-size-5: 1.44rem;
    --font-size-6: 1.728rem;
    --font-size-7: 2.074rem;
    --font-size-8: 2.488rem;
    --font-size-9: 2.986rem;
}

* {
    max-width: 100vw;
}

a {
    font-size: var(--font-size-3);
    color: var(--color-1);
}

body {
    font-family: var(--font-1);
    background-color: var(--color-10);
    color: var(--color-7);
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

button {
    border-style: solid;
    border-width: 0px;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

h1 {
    font-family: var(--font-2);
    font-size: var(--font-size-9);
    font-weight: bolder;
    text-wrap: balance;
    font-style: normal;
}

h2 {
    font-family: var(--font-2);
    font-size: var(--font-size-8);
    font-weight: bolder;
    text-wrap: balance;
}

h3 {
    font-family: var(--font-2);
    font-size: var(--font-size-7);
    font-weight: bolder;
    text-wrap: balance;
}

h4 {
    font-family: var(--font-2);
    font-size: var(--font-size-6);
    font-weight: bolder;
    text-wrap: balance;
}

h5 {
    font-family: var(--font-2);
    font-size: var(--font-size-5);
    font-weight: bolder;
    text-wrap: balance;
}

h6 {
    font-family: var(--font-2);
    font-size: var(--font-size-4);
    font-weight: bolder;
    text-wrap: balance;
}

html {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

li {
}

p {
    font-size: var(--font-size-3);
    text-wrap: pretty;
}

small {
    font-size: var(--font-size-2);
}

ul {
    list-style-type: none;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    background: gray;
}

    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 255, 255, 0.3);
    }

.button-1 {
    font-weight: bold;
    background-color: var(--color-1);
    color: var(--color-6);
    text-decoration: none;
    border-style: solid;
    border-width: 0px;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.button-2 {
    font-weight: bold;
    background-color: var(--color-14);
    color: var(--color-1);
    text-decoration: none;
    border-style: solid;
    border-width: 3px;
    border-color: var(--color-1);
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.button-3 {
    font-weight: bold;
    background-color: var(--color-14);
    color: var(--color-1);
    text-decoration: underline;
    border-style: solid;
    border-width: 0px;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.SlideshowPanel .button-pagination {
    background-color: var(--color-14);
    color: var(--color-8);
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-top: 1rem;
}

    .SlideshowPanel .button-pagination:hover {
        background-color: var(--color-14);
    }

    .SlideshowPanel .button-pagination::before {
        font-family: 'Font Awesome 6 free';
        content: '\f111';
    }

.SlideshowPanel .button-pagination-selected {
    background-color: var(--color-14);
    color: var(--color-2);
}

.section-main {
    width: 100vw;
    min-height: 100vh;
}

.section-header {
    background-color: var(--color-12);
    color: var(--color-5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
}

    .section-header a {
        color: var(--color-5);
    }

    .section-header img {
        height: 3rem;
    }

.Home .div-PanelHero .PanelHero {
    width: 100vw;
}

    .Home .div-PanelHero .PanelHero .div-image {
        display: block;
        text-align: center;
        position: relative;
    }

        .Home .div-PanelHero .PanelHero .div-image img {
            width: 100vw;
            filter: brightness(75%);
        }

        .Home .div-PanelHero .PanelHero .div-image .div-text {
            text-align: left;
            position: absolute;
            left: 4rem;
            top: 50%;
            transform: translate(-0%, -50%);
            max-width: 33rem;
        }

            .Home .div-PanelHero .PanelHero .div-image .div-text h1 {
                color: var(--color-10);
            }

            .Home .div-PanelHero .PanelHero .div-image .div-text p {
                color: var(--color-10);
            }

.Home {
    position: static;
    top: 4.67rem;
    margin-top: 4.5rem;
}

    .Home .button-3 {
    }

.section-footer .PageFooter .div-benefits {
    background-color: var(--color-9);
    color: var(--color-4);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

    .section-footer .PageFooter .div-benefits a {
        color: var(--color-4);
    }

    .section-footer .PageFooter .div-benefits .div-content {
        display: flex;
        justify-content: center;
    }

    .section-footer .PageFooter .div-benefits .div-items .div-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

.section-footer .PageFooter .div-information {
    background-color: var(--color-9);
    color: var(--color-4);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

    .section-footer .PageFooter .div-information a {
        color: var(--color-4);
    }

    .section-footer .PageFooter .div-information .div-content {
        display: flex;
        justify-content: center;
    }

    .section-footer .PageFooter .div-information .div-items .div-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

.section-footer .PageFooter .div-interactive {
    background-color: var(--color-9);
    color: var(--color-4);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

    .section-footer .PageFooter .div-interactive a {
        color: var(--color-4);
    }

    .section-footer .PageFooter .div-interactive img {
        max-height: 2rem;
        filter: grayscale(100%) opacity(25%);
    }

    .section-footer .PageFooter .div-interactive li {
        float: left;
        margin-right: 0.25rem;
    }

    .section-footer .PageFooter .div-interactive ul {
        list-style-type: none;
    }

    .section-footer .PageFooter .div-interactive .div-content {
        display: flex;
        flex: none;
        justify-content: center;
    }

    .section-footer .PageFooter .div-interactive .div-items .div-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

.section-footer .PageFooter .div-copyright {
    background-color: var(--color-9);
    color: var(--color-4);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

    .section-footer .PageFooter .div-copyright a {
        color: var(--color-4);
    }

    .section-footer .PageFooter .div-copyright .button-1 {
    }

    .section-footer .PageFooter .div-copyright .div-content {
        display: flex;
        justify-content: center;
        border-style: solid;
        border-width: 0px;
    }

.section-content {
    background-color: var(--color-10);
    color: var(--color-5);
}

    .section-content a {
        color: var(--color-5);
    }

.Home .div-PanelTestimonials {
    background-color: var(--color-10);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 0rem;
}

    .Home .div-PanelTestimonials img {
    }

    .Home .div-PanelTestimonials .div-content {
        display: initial;
        text-align: center;
    }

        .Home .div-PanelTestimonials .div-content .div-header {
            padding-bottom: 3rem;
        }

.Home .div-PanelCustomers {
    background-color: var(--color-10);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

    .Home .div-PanelCustomers .PanelCustomers .div-image {
        background-color: var(--color-10);
        border-radius: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.25rem;
    }

        .Home .div-PanelCustomers .PanelCustomers .div-image img {
            width: 8rem;
            filter: opacity(75%);
        }

.Home .div-PanelFaqs {
    background-color: var(--color-1);
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

    .Home .div-PanelFaqs .PanelFaqs .AccordionPanel {
    }

        .Home .div-PanelFaqs .PanelFaqs .AccordionPanel button {
            background-color: var(--color-1);
            border-radius: 0rem;
        }

        .Home .div-PanelFaqs .PanelFaqs .AccordionPanel div {
            background-color: var(--color-1);
            text-align: left;
        }

        .Home .div-PanelFaqs .PanelFaqs .AccordionPanel p {
            font-size: var(--font-size-2);
        }

        .Home .div-PanelFaqs .PanelFaqs .AccordionPanel .AccordionItem {
            background-color: var(--color-1);
        }

            .Home .div-PanelFaqs .PanelFaqs .AccordionPanel .AccordionItem button {
            }

.Home .div-PanelBenefits {
    background-color: var(--color-1);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

    .Home .div-PanelBenefits button {
    }

    .Home .div-PanelBenefits .PanelBenefits .ColumnPanel .div-benefit {
        margin-left: 1rem;
        margin-right: 1rem;
    }

        .Home .div-PanelBenefits .PanelBenefits .ColumnPanel .div-benefit h2 {
            text-align: center;
        }

        .Home .div-PanelBenefits .PanelBenefits .ColumnPanel .div-benefit h5 {
            text-align: center;
        }

.section-footer .PageFooter {
}

    .section-footer .PageFooter a {
        text-decoration: none;
    }

        .section-footer .PageFooter a:hover {
            text-decoration: underline;
        }

.Home .div-PanelProducts .PanelProducts {
}

    .Home .div-PanelProducts .PanelProducts .div-content .div-header {
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 3rem;
    }

    .Home .div-PanelProducts .PanelProducts .div-items .div-item {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

.Home .div-PanelProducts {
    background-color: var(--color-10);
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

    .Home .div-PanelProducts img {
    }

    .Home .div-PanelProducts .PanelProducts .div-product {
        background-color: var(--color-9);
        display: flex;
        border-radius: 0.5rem;
        width: 38rem;
        height: 21rem;
        overflow-x: hidden;
        overflow-y: hidden;
    }

        .Home .div-PanelProducts .PanelProducts .div-product h1 {
        }

        .Home .div-PanelProducts .PanelProducts .div-product .div-information {
            background-color: var(--color-11);
            color: var(--color-6);
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

            .Home .div-PanelProducts .PanelProducts .div-product .div-information h1 {
                font-size: var(--font-size-6);
            }

            .Home .div-PanelProducts .PanelProducts .div-product .div-information h2 {
                font-size: var(--font-size-3);
            }

            .Home .div-PanelProducts .PanelProducts .div-product .div-information p {
                font-size: var(--font-size-2);
            }

        .Home .div-PanelProducts .PanelProducts .div-product .div-image {
            background-color: var(--color-1);
            display: flex;
            justify-content: center;
            border-style: solid;
            border-width: 0px;
            border-color: var(--color-6);
            width: 21rem;
            min-width: 21rem;
            max-width: 21rem;
        }

            .Home .div-PanelProducts .PanelProducts .div-product .div-image img {
                height: 21rem;
                max-width: 21rem;
            }

.Home .div-PanelSolutions .PanelSolutions {
}

    .Home .div-PanelSolutions .PanelSolutions .div-content .div-header {
        padding-bottom: 3rem;
    }

    .Home .div-PanelSolutions .PanelSolutions .div-items .div-item {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

.Home .div-PanelSolutions {
    background-color: var(--color-10);
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

    .Home .div-PanelSolutions img {
    }

    .Home .div-PanelSolutions .PanelSolutions .div-solution {
        background-color: var(--color-9);
        display: flex;
        border-radius: 0.5rem;
        width: 25rem;
        height: 21rem;
        overflow-x: hidden;
        overflow-y: hidden;
    }

        .Home .div-PanelSolutions .PanelSolutions .div-solution h1 {
        }

        .Home .div-PanelSolutions .PanelSolutions .div-solution .div-information {
            background-color: var(--color-6);
            color: var(--color-1);
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

            .Home .div-PanelSolutions .PanelSolutions .div-solution .div-information h1 {
                font-size: var(--font-size-6);
            }

            .Home .div-PanelSolutions .PanelSolutions .div-solution .div-information h2 {
                font-size: var(--font-size-3);
            }

            .Home .div-PanelSolutions .PanelSolutions .div-solution .div-information p {
                font-size: var(--font-size-2);
            }

        .Home .div-PanelSolutions .PanelSolutions .div-solution .div-image {
            background-color: var(--color-1);
            display: flex;
            justify-content: center;
            border-style: solid;
            border-width: 0px;
            border-color: var(--color-6);
            width: 21rem;
            min-width: 21rem;
            max-width: 21rem;
        }

            .Home .div-PanelSolutions .PanelSolutions .div-solution .div-image img {
                height: 21rem;
                max-width: 21rem;
            }

.section-footer {
    font-size: var(--font-size-2);
}

    .section-footer a {
        font-size: var(--font-size-2);
    }

    .section-footer h1 {
        font-size: var(--font-size-2);
    }

    .section-footer h2 {
        font-size: var(--font-size-3);
    }

    .section-footer h3 {
        font-size: var(--font-size-2);
    }

    .section-footer h4 {
    }

    .section-footer h5 {
        font-size: var(--font-size-4);
    }

    .section-footer p {
        font-size: var(--font-size-2);
    }

.Home .div-PanelTestimonials .PanelTestimonials {
}

    .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-message .span-quotes {
        font-size: var(--font-size-9);
        color: var(--color-8);
    }

    .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-message {
        display: flex;
        justify-content: center;
        width: 100%;
    }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-message h1 {
        }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-message p {
            font-style: italic;
        }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-message table {
            width: 35rem;
        }

    .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial {
        width: 96vw;
    }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-name {
        }

            .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-name p {
                font-weight: bold;
            }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-position .span-position {
            color: var(--color-8);
        }

            .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-position .span-position p {
            }

        .Home .div-PanelTestimonials .PanelTestimonials .div-testimonial .div-position .span-company {
            font-weight: bold;
            color: var(--color-8);
        }

.Home .div-PanelFaqs .PanelFaqs {
}

.Product {
    position: static;
    margin-top: 4.5rem;
}

    .Product .div-information {
        background-color: var(--color-12);
        display: flex;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

        .Product .div-information img {
            max-height: 25rem;
        }

        .Product .div-information .div-description {
            margin-left: 3rem;
        }

            .Product .div-information .div-description img {
                margin-bottom: 0.5rem;
                height: 1.5rem;
                filter: grayscale(1000%) opacity(100%);
            }

        .Product .div-information .div-images {
            text-align: center;
            width: 50%;
            min-width: 50%;
            max-width: 50%;
        }

    .Product .div-additional {
        padding-left: 0rem;
    }

        .Product .div-additional h1 {
            font-size: var(--font-size-6);
            margin-top: 2rem;
        }

        .Product .div-additional .nav {
            background-color: var(--color-12);
        }

        .Product .div-additional .tab-content .tab-pane {
            background-color: var(--color-12);
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

            .Product .div-additional .tab-content .tab-pane .active {
                background-color: var(--color-12);
            }

        .Product .div-additional .div-specifications {
        }

            .Product .div-additional .div-specifications label {
                font-weight: bold;
                min-width: 9rem;
            }

            .Product .div-additional .div-specifications td {
                vertical-align: top;
                border-style: solid;
                border-width: 0px;
                padding-left: 1rem;
                padding-right: 1rem;
            }

.Information {
    padding-bottom: 5rem;
    margin-top: 5rem;
}

    .Information .div-content .div-header {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 3rem;
    }

        .Information .div-content .div-header h1 {
        }

    .Information .div-content {
        text-align: center;
    }

        .Information .div-content .div-information {
            text-align: left;
            padding-left: 3rem;
            padding-right: 3rem;
            padding-top: 2rem;
            padding-bottom: 3rem;
        }

.section-header .PageHeader {
    background-color: var(--color-1);
}

    .section-header .PageHeader i {
    }

    .section-header .PageHeader img {
        filter: hue-rotate(-15deg) saturate(260%);
    }




.Contact {
    position: static;
    top: 4.67rem;
    margin-top: 4.5rem; 
}

.Contact .form-group { 
    margin-bottom:1rem;
}

.requiredField {
    color: var(--color-2);
} 